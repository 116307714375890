// src/store/reducer.js
import {
    FETCH_PRODUCTS_REQUEST,
    FETCH_PRODUCTS_SUCCESS,
    FETCH_PRODUCTS_FAILURE,
  } from './actions';
  
  const initialState = {
    newProducts: [],
    loading: false,
    error: null,
  };
  
  const reducer = (state = initialState, action) => {
    console.log("reducer ",action.payload);
    switch (action.type) {
      case FETCH_PRODUCTS_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case FETCH_PRODUCTS_SUCCESS:
        console.log("reducer ",action.payload);
        return {
          ...state,
          newProducts: action.payload,
          loading: false,
        };
      case FETCH_PRODUCTS_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default reducer;