import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { save, load } from "redux-localstorage-simple";
import { Provider } from "react-redux";
import { fetchProducts } from "./redux/actions/productActions";
import rootReducer from "./redux/reducers/rootReducer";
import products from "./data/products.json";
import App from "./App";
import "./assets/scss/style.scss";
import reportWebVitals from './reportWebVitals';

import { composeWithDevTools } from "redux-devtools-extension";
import new_store from "./new_store";
import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client";
// import new_store from "../new_store";

const client = new ApolloClient({
  uri: "https://cms.vidhaantextiles.com/graphql",
  cache: new InMemoryCache()
});
const store = createStore(
  rootReducer,
  load(),
  composeWithDevTools(applyMiddleware(thunk, save()))
);

// fetch products from json file
store.dispatch(fetchProducts(products));

ReactDOM.render(
  // <Provider store={new_store}>
  <Provider store={store}>
  <ApolloProvider client={client}>
    <App />
    </ApolloProvider>
    {/* </Provider> */}
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
reportWebVitals();
